// 此文件是通过脚本生成的，直接编辑无效！！！

// 不需要导航框架的页面路径
export const noFrames = [
    '/login1',
    '/login',
];

// 不需要登录就可以访问的页面路径
export const noAuths = [
    '/login1',
    '/login',
];

// 需要keep alive 页面
export const keepAlives = [
    {
        path: '/',
        keepAlive: false,
    },
    {
        path: '/iframe_page_/:src',
        keepAlive: true,
    },
    {
        path: '/login1',
        keepAlive: false,
    },
    {
        path: '/login',
        keepAlive: false,
    },
];

// 页面路由配置
export default [
    {
        path: '/advertising-settings',
        component: () => import('E:/uni星球/unifamily-new/unifamily/communitymanager/src/pages/advertising-settings/index.jsx'),
    },
    {
        path: '/example/ajax',
        component: () => import('E:/uni星球/unifamily-new/unifamily/communitymanager/src/pages/examples/ajax/index.jsx'),
    },
    {
        path: '/example/customer-header',
        component: () => import('E:/uni星球/unifamily-new/unifamily/communitymanager/src/pages/examples/customer-header/index.jsx'),
    },
    {
        path: '/forum-manager',
        component: () => import('E:/uni星球/unifamily-new/unifamily/communitymanager/src/pages/forum-manager/index.jsx'),
    },
    {
        path: '/',
        component: () => import('E:/uni星球/unifamily-new/unifamily/communitymanager/src/pages/home/index.jsx'),
    },
    {
        path: '/iframe_page_/:src',
        component: () => import('E:/uni星球/unifamily-new/unifamily/communitymanager/src/pages/iframe/index.jsx'),
    },
    {
        path: '/level-manager',
        component: () => import('E:/uni星球/unifamily-new/unifamily/communitymanager/src/pages/level-manager/index.jsx'),
    },
    {
        path: '/login1',
        component: () => import('E:/uni星球/unifamily-new/unifamily/communitymanager/src/pages/login/index.jsx'),
    },
    {
        path: '/login',
        component: () => import('E:/uni星球/unifamily-new/unifamily/communitymanager/src/pages/login1/index.jsx'),
    },
    {
        path: '/other-configurations',
        component: () => import('E:/uni星球/unifamily-new/unifamily/communitymanager/src/pages/other-configurations/index.jsx'),
    },
    {
        path: '/plate-manager',
        component: () => import('E:/uni星球/unifamily-new/unifamily/communitymanager/src/pages/plate/index.jsx'),
    },
    {
        path: '/precut-posts',
        component: () => import('E:/uni星球/unifamily-new/unifamily/communitymanager/src/pages/precut-posts/index.jsx'),
    },
    {
        path: '/precut-users',
        component: () => import('E:/uni星球/unifamily-new/unifamily/communitymanager/src/pages/precut-users/index.jsx'),
    },
    {
        path: '/settings',
        component: () => import('E:/uni星球/unifamily-new/unifamily/communitymanager/src/pages/setting/index.jsx'),
    },
    {
        path: '/system-message',
        component: () => import('E:/uni星球/unifamily-new/unifamily/communitymanager/src/pages/system-messages/index.jsx'),
    },
    {
        path: '/tags',
        component: () => import('E:/uni星球/unifamily-new/unifamily/communitymanager/src/pages/tags/index.jsx'),
    },
    {
        path: '/today-topic',
        component: () => import('E:/uni星球/unifamily-new/unifamily/communitymanager/src/pages/today-topic/index.jsx'),
    },
    {
        path: '/user_orders/_/edit/:id',
        component: () => import('E:/uni星球/unifamily-new/unifamily/communitymanager/src/pages/user-orders/Edit.jsx'),
    },
    {
        path: '/user-orders',
        component: () => import('E:/uni星球/unifamily-new/unifamily/communitymanager/src/pages/user-orders/index.jsx'),
    },
    {
        path: '/users/_/edit/:id',
        component: () => import('E:/uni星球/unifamily-new/unifamily/communitymanager/src/pages/users/Edit.jsx'),
    },
    {
        path: '/users',
        component: () => import('E:/uni星球/unifamily-new/unifamily/communitymanager/src/pages/users/index.jsx'),
    },
];
